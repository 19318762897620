import { EmptyFlexColumn, Flex, FlexItem } from "sub/blocks/FlexBoxes";
import { InfoLabel, Link, Tag, Text, Image, tokens, PopoverSurface, Popover, Button, MessageBar, MessageBarBody } from "@fluentui/react-components";
import { ChatBubblesQuestionRegular, GlobeRegular, NotepadPersonRegular, WarningFilled } from "@fluentui/react-icons";
import { app as microsoftTeams } from "@microsoft/teams-js";
import { hyDate, link_purchase, link_teams_app, transformDateToDayMonthYear } from "sub/library/epCommon";
import { hyDebug } from "sub/library/hyDebug";
import { EnumPollWorkflow } from "sub/context/PollContext";
import { AppContextClass, EnumAppEnvironment } from "sub/context/AppContext";
import { backendTrackEvent } from "sub/library/epBackend";


export function ImprintTab() {
    return (
        <FlexItem column push>
            <Flex justifyContent="center" wrap gap="smaller" alignItems="center">
                <ChatBubblesQuestionRegular fontSize={20} />
                <Link href="mailto:support@easy-poll.app" target="_blank" appearance="subtle">Support</Link>
                <EmptyFlexColumn size="0.5" />
                <NotepadPersonRegular fontSize={20} />
                <Link href="https://forms.office.com/e/Fry6g8Mc0Z" target="_blank" appearance="subtle">Suggest a feature</Link>
                <EmptyFlexColumn size="0.5" />
                <GlobeRegular fontSize={20} />
                <Link href="https://www.easy-poll.app" target="_blank" appearance="subtle">easy-poll.app</Link>
            </Flex>
        </FlexItem>
    );
}

export function ImprintChat() {
    return (
        <FlexItem column push>
            <Flex justifyContent="center" wrap gap="smaller" alignItems="center">
                <Image src="iconApp.png" height={"16px"} />
                <Link onClick={_openApp} style={{ color: tokens.colorNeutralForeground3, fontSize: tokens.fontSizeBase300 }}>Open app</Link>
                <EmptyFlexColumn size="0.5" />
                <GlobeRegular fontSize={20} />
                <Link href="https://www.easy-poll.app" target="_blank" appearance="subtle">easy-poll.app</Link>
            </Flex>
        </FlexItem>
    );


    function _openApp() {
        try {
            microsoftTeams.openLink(link_teams_app);
            hyDebug("Successfully navigated to the personal app.");
        } catch (ex: any) {
            hyDebug("Failed to navigate to the personal app: " + ex.message);

        }
    }
}



export function ProTag() {
    return (
        <Tag size="extra-small"
            style={{
                backgroundColor: tokens.colorStatusSuccessBackground1,
                borderColor: tokens.colorStatusSuccessForeground1,
                color: tokens.colorStatusSuccessForeground1,
            }}
        >
            Pro
        </Tag>
    );
}

export function DraftTag() {
    return (
        <Tag size="extra-small"
            style={{
                backgroundColor: tokens.colorPaletteMarigoldBackground1,
                borderColor: tokens.colorPaletteMarigoldForeground2,
                color: tokens.colorPaletteMarigoldForeground2,
                padding: "0 0 0 0"
            }}
        >
            Draft
        </Tag>
    );
}


export function TextFreePlan() {
    return (<Text weight="semibold" style={{ color: tokens.colorNeutralForeground4 }}>Free plan</Text>);
}

export function TextFreePlanLimit(props: { template: boolean }) {
    if (props.template) {
        // Template Text
        return (<Text wrap size={200}><b>Free plan:</b> Create unlimited templates. When you use the template later, you can create a maximum of <b>2 polls</b> per month with a maximum of <b>25 votes</b> per poll. Upgrade to Pro for more. <a href="https://www.easy-poll.app/buy" target="_blank">Learn more</a>.</Text>);
    } else {
        // Poll Text
        return (<Text wrap size={200}><b>Free plan:</b> You can use Easy Poll for free with a maximum of <b>2 polls</b> per month and <b>25 votes</b> per poll. If your needs are greater, please switch to the Pro plan. <a href="https://www.easy-poll.app/buy" target="_blank">Learn more</a>.</Text>);
    }
}

export function TextFreePlanRestrictionChat() {
    return (<Text wrap size={300}><WarningFilled color={tokens.colorPaletteGrapeBorderActive} /> <Text size={200} style={{ color: tokens.colorPaletteGrapeBorderActive }} weight="bold">Limit: </Text>Thank you for using Easy Poll! You have reached the <b>limits of the free plan</b> with this poll. You will need a <b>Pro license</b> to post it and view the results. <a href="https://www.easy-poll.app/buy" target="_blank">Purchase now</a>.</Text>);
}

export function TextFreePlanRestrictionTab() {
    return (<Text wrap size={300}><WarningFilled color={tokens.colorPaletteGrapeBorderActive} /> <Text size={200} style={{ color: tokens.colorPaletteGrapeBorderActive }} weight="bold">Limit: </Text> Thank you for using Easy Poll. You have reached the <b>limits of the free plan</b> with this poll. You can create the draft now, but you will need a <b>Pro license</b> to post it later. <a href="https://www.easy-poll.app/buy" target="_blank">Purchase now</a>.</Text>);
}

export function WarningFreePlanRestriction() {
    return (
        <>
            <Flex alignItems="center" gap="smallest">
                <WarningFilled color={tokens.colorPaletteGrapeBorderActive} /> <InfoLabel size="small" weight="semibold" style={{ color: tokens.colorPaletteGrapeBorderActive }} label={"Limit"} info={<Text>Thank you for using Easy Poll! You have reached the limits of the free plan with this poll. You will need a Pro license to post it and view the results. <a href="https://www.easy-poll.app/buy" target="_blank">Purchase now</a>.</Text>} />
            </Flex>
        </>
    );
}
export function WarningRestrictionUnlocked(props: { appContext: AppContextClass, dateUntil: string }) {
    const dateString = transformDateToDayMonthYear(props.appContext.appAuthData.locale, hyDate(props.dateUntil));

    return (
        <>
            <Flex alignItems="center" gap="smallest">
                <WarningFilled color={tokens.colorStatusSuccessForeground2} /> <InfoLabel size="small" weight="semibold" style={{ color: tokens.colorStatusSuccessForeground2 }} label={"Revealed until " + dateString} info={<Text>Thank you for using Easy Poll! You have reached the limits of the free plan with this poll. It has been temporarily unlocked by the poll creator. Please finish the poll and save the results before it is locked again. To permanently unlock this poll and create unlimited polls with unlimited votes, please purchase a Pro license. <a href="https://www.easy-poll.app/buy" target="_blank">Purchase now</a>.</Text>} />
            </Flex>
        </>
    );
}

export function PopupFreePlanLimit(props: { appContext: AppContextClass, situation: "pro_option" | "post", refElement: React.MutableRefObject<HTMLButtonElement | HTMLInputElement | null>, fShowPopup: (val: boolean) => void }) {
    let popuptext = <></>;
    switch (props.situation) {
        case "post":
            popuptext = <>Thank you for using Easy Poll! You have reached the limits of the free plan with this poll. Please upgrade to the Pro plan to post it.</>
            break;
        case "pro_option":
            popuptext = <>Thank you for using Easy Poll! You need a Pro license to use this feature. Upgrade now and create unlimited polls with all features.</>
            break;
    }

    return (
        <Popover withArrow
            positioning={{
                target: props.refElement.current, // Attach to the Input element
                align: 'start', // Align Popover to the center of the Input element
            }}
            open={true}
            onOpenChange={(e, data) => { props.fShowPopup(data.open); }}
        >

            <PopoverSurface >
                <Flex column style={{ maxWidth: "16em" }} gap="medium">
                    <Text weight="semibold">Free plan limit</Text>
                    <Text wrap>{popuptext}</Text>
                    <Flex fillFlex gap="small" justifyContent="space-around">
                        <Button onClick={() => { _not_now(); }}>Not now</Button>
                        <Button appearance="primary" onClick={() => { _purchase_button(); }}>Upgrade</Button>
                    </Flex>
                </Flex>
            </PopoverSurface>
        </ Popover>
    );

    function _not_now() {
        props.fShowPopup(false);
    }

    function _purchase_button() {
        backendTrackEvent(props.appContext.appAuthData.lang, "Tab", "Upgrade.Purchase", "", props.appContext);
        window.open(link_purchase, '_blank');
        props.fShowPopup(false);
    }
}

export function TextPreviewWorkflowInfo(props: { appEnvironment: EnumAppEnvironment, poll_is_template: boolean, workflow: EnumPollWorkflow }) {
    let title_info = "";

    if (props.appEnvironment === EnumAppEnvironment.message_chat) {
        // Chat text
        title_info = (props.workflow === EnumPollWorkflow.reusePoll_preview ? "Post an existing poll" : "Create a new poll");
    }

    if (props.appEnvironment === EnumAppEnvironment.personal_tab) {
        // Tab Text
        if (props.workflow === EnumPollWorkflow.editPoll_preview || props.workflow === EnumPollWorkflow.editPoll_preview_close) {
            title_info = (props.poll_is_template ? "Edit an existing template" : "Edit an existing poll");
        } else {
            title_info = (props.poll_is_template ? "Create a template" : "Create a new poll");
        }
    }

    if (title_info) {
        return (<Text weight="semibold" style={{ color: tokens.colorNeutralForeground4 }}>{title_info}</Text>);
    } else {
        // Default
        return (<></>);
    }
}


export function WarningEditMode(props: { is_template: boolean }) {
    return (
        <MessageBar intent="warning" layout="auto" icon={null}>
            {!props.is_template && (<MessageBarBody><b>Edit mode:</b> Some options can not be changed when editing an existing poll. <InfoLabel info={<Text wrap style={{ maxWidth: "5em" }}>You can edit the question and answers. If you delete an answer, all corresponding votes will also be removed. You can make a poll anonymous, but once a poll is anonymous, it cannot be reverted to a non-anonymous state. Similarly, you can set a poll to be visible only to the creator, but once this setting is applied, it cannot be undone.</Text>} /></MessageBarBody>)}
            {props.is_template && (<MessageBarBody><b>Edit mode:</b> Editing the template will only affect new polls.</MessageBarBody>)}
        </MessageBar>
    );

}