import { Dialog, Button, DialogSurface, DialogTitle, DialogContent, Text, DialogActions, Image, DialogBody, Spinner } from "@fluentui/react-components";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Flex, FlexItem } from "sub/blocks/FlexBoxes";
import { TabContext, TabContextClass, TabDialogType, TabTableMode } from "sub/context/TabContext";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { PollDataClass } from "sub/bot-client-shared/PollDataClass";
import { EnumPollWorkflow, PollContext, PollContextClass } from "sub/context/PollContext";
import { PollCreateOrEdit } from "./PollCreateOrEdit";
import { PollPreview } from "./PollPreview";
import { dialog_close_refresh, getPollDataToEditPollEntry, getPollDataToNewPollEntry, refreshTicker } from "sub/library/epCommon";
import { AppContext, AppContextClass } from "sub/context/AppContext";

export function TabDialogCreateDraft() {
    const tabContext = useContext(TabContext);
    const appContext = useContext(AppContext);

    // Define context
    const [contextPollData, setContextPollData] = useState(new PollDataClass());
    const updatePollData = (updates: Partial<PollDataClass>) => {
        setContextPollData(current => ({ ...current, ...updates }));
    };

    // Start with New Poll Entry or Duplicate Poll Loading:
    const [pollWorkflow, setPollWorkflow] = useState(() => {
        switch (tabContext.tabDialog) {
            case TabDialogType.EditPoll: // Also used for templates
                return EnumPollWorkflow.editPoll_loading;

            case TabDialogType.DuplicateTemplate:
                return EnumPollWorkflow.duplicateTemplate_loading;

            case TabDialogType.DuplicatePoll:
                return EnumPollWorkflow.duplicatePoll_loading;

            case TabDialogType.CreateTemplate:
                return EnumPollWorkflow.newTemplate_entry;

            default:
            case TabDialogType.CreateDraft:
                return EnumPollWorkflow.newPoll_entry;
        }

    });

    const pollContext: PollContextClass =
    {
        pollWorkflow: pollWorkflow,
        setPollWorkflow: setPollWorkflow,

        pollData: contextPollData,
        updatePollData: updatePollData,
    };

    // One time code only for tab mode.
    useEffect(() => {
        if (!appContext.appAuthData.hasActiveLicense) {
            // Refresh authorization data once
            // This will update the poll limit warning in the GUI
            appContext.setAuthRefreshTicker(refreshTicker);
        }
    }, []);


    // Poll Workflow handling
    useEffect(() => {

        // Close dialog when poll workflow is finished.
        if (pollWorkflow === EnumPollWorkflow.close) {
            tabContext.setTabDialog(TabDialogType.None);
        }

        // Duplicate the poll and proceed with new poll entry.
        if (pollWorkflow === EnumPollWorkflow.duplicatePoll_loading || pollWorkflow === EnumPollWorkflow.duplicateTemplate_loading) {
            const target_is_template = (pollWorkflow === EnumPollWorkflow.duplicateTemplate_loading);

            const fetchData = async () => {
                await getPollDataToNewPollEntry(target_is_template, tabContext, appContext, setPollWorkflow, setContextPollData);
            }

            fetchData();
        }

        // Get poll data and proceed with edit poll entry.
        if (pollWorkflow === EnumPollWorkflow.editPoll_loading) {
            const fetchData = async () => {
                await getPollDataToEditPollEntry(tabContext, appContext, setPollWorkflow, setContextPollData);
            }

            fetchData();
        }


        // Close dialog when poll workflow is finished.
        // Also update data for tab.
        if (pollWorkflow === EnumPollWorkflow.newPoll_preview_close) {

            // Add pollData to listPollResults, transform for table and update GUI immediately.
            // (Only if the mode matches the poll template information)
            if (
                (!contextPollData.is_template && tabContext.tableMode === TabTableMode.modePolls) ||
                (contextPollData.is_template && tabContext.tableMode === TabTableMode.modeTemplates)
            ) {
                tabContext.listPollResults.current.polls.unshift(contextPollData);
                tabContext.setRefreshTickerGUI(refreshTicker);
            }

            // Switch to correct mode depending on is_template information
            tabContext.setTableMode(contextPollData.is_template ? TabTableMode.modeTemplates : TabTableMode.modePolls);

            // Initiate real data update.
            tabContext.setRefreshTickerData(refreshTicker);
            tabContext.setTabDialog(TabDialogType.None);
        }

        // Close dialog when poll workflow is finished.
        // Also update data for tab.
        if (pollWorkflow === EnumPollWorkflow.editPoll_preview_close) {
            
            
            const index = tabContext.listPollResults.current.polls.findIndex(poll => poll.poll_guid === contextPollData.poll_guid);
            if (index !== -1) {
                tabContext.listPollResults.current.polls[index] = contextPollData;
                tabContext.setRefreshTickerGUI(refreshTicker);
            }
            
            // Initiate real data update.
            tabContext.setRefreshTickerData(refreshTicker);
            tabContext.setTabDialog(TabDialogType.None);
        }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pollWorkflow]);

    const dialogTitle: string = (() => {
        switch (pollWorkflow) {
            case EnumPollWorkflow.editPoll_loading:
            case EnumPollWorkflow.editPoll_entry:
                if (contextPollData.is_template || tabContext.activePollElement.current.is_template) {
                    return "Edit template";
                } else {
                    return "Edit poll";
                }

            case EnumPollWorkflow.newPoll_preview:
            case EnumPollWorkflow.newPoll_preview_close:
            case EnumPollWorkflow.editPoll_preview:
            case EnumPollWorkflow.editPoll_preview_close:
                return "Preview";

            case EnumPollWorkflow.duplicateTemplate_loading:
            case EnumPollWorkflow.newTemplate_entry:
                return "Create a new template";

            case EnumPollWorkflow.duplicatePoll_loading:
            case EnumPollWorkflow.newPoll_entry:
            default:
                return "Draft a new poll";
        }
    })();

    return (
        <PollContext.Provider value={pollContext}>
            <Dialog defaultOpen onOpenChange={(event, data) => { if (!data.open) { dialog_close_refresh(tabContext); } }}>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle action={<Button appearance="subtle" aria-label="close" onClick={() => { dialog_close_refresh(tabContext); }} icon={<Dismiss24Regular />} />}>{dialogTitle}</DialogTitle>
                        <DialogContent style={{ maxHeight: "70vh" }}>
                            <Flex column fillFlex gap="smaller">

                                {(pollWorkflow === EnumPollWorkflow.duplicatePoll_loading || pollWorkflow === EnumPollWorkflow.duplicateTemplate_loading || pollWorkflow === EnumPollWorkflow.editPoll_loading) &&
                                    <Flex column fillFlex justifyContent="center">
                                        <Spinner />
                                    </Flex>
                                }

                                {(pollWorkflow === EnumPollWorkflow.newPoll_entry || pollWorkflow === EnumPollWorkflow.newTemplate_entry || pollWorkflow === EnumPollWorkflow.editPoll_entry || pollWorkflow === EnumPollWorkflow.close) && <PollCreateOrEdit />}

                                {(pollWorkflow === EnumPollWorkflow.newPoll_preview || pollWorkflow === EnumPollWorkflow.editPoll_preview || pollWorkflow === EnumPollWorkflow.newPoll_preview_close || pollWorkflow === EnumPollWorkflow.editPoll_preview_close) && <PollPreview />}
                            </Flex>

                        </DialogContent>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </PollContext.Provider>
    );


}
