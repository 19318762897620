// environment is a global variable that is set to true in development and false in production
// import differs between client and bot.

import { environment } from "../../library/epCommon";
// import { environment } from "../../library/botCommon";

import { CreateEditVoteResults, CreateEditVoteWarnings, CreatePollResults, EditPollWarnings } from "../../library/epBackendTypes";
// import { CreateEditVoteResults, CreateEditVoteWarnings, CreatePollResults, EditPollWarnings } from "../../library/botBackendTypes";

// ------------------ identical shared code ------------------

import * as ACData from "adaptivecards-templating";

import templateCardPoll from "./templatePoll.json";
import templateCardPollResult from "./templatePollResult.json";
import templateCardPollResultThankYou from "./templatePollResultThankYou.json";
import templatePollResultRestricted from "./templatePollResultRestricted.json";
import templateCardStandardText from "./templateStandardText.json";
import templateDialogInstallBot from "./templateDialogInstallBot.json";
import templateDialogStartConversation from "./templateDialogStartConversation.json";

import { EnumPolLView, PollDataClass } from "../PollDataClass";
import { hyDate, transformDateToDayMonthYear } from "../../library/epCommon";

export class Warning extends Error { };
export class UserError extends Error { };

export const cardStatus200Card = "application/vnd.microsoft.card.adaptive";
export const cardStatus200Message = "application/vnd.microsoft.activity.message";

export const actionPollUnlockResults = "pollUnlockResults";
export const actionPollSubmitVote = "pollSubmitVote";
export const actionResultsCardRefresh = "personalResultsCardRefresh";
export const actionResultsCardEditVote = "personalResultsEditVote";
export const actionShowResultDetails = "showResultDetails";

export const actionGlobalPollCardRefresh = "globalPollCardRefresh";
export const actionGlobalOpenPoll = "globalOpenPoll";
export const actionGlobalClosePoll = "globalClosePoll";

export const actionGlobalRemovePoll = "globalRemovePoll";
export const actionGlobalRemoveCard = "globalRemoveCard";

export const cdn_baseurl = (environment === 'local') ? "https://cdn.easy-poll.app/local" : "https://cdn.easy-poll.app/app"

function getPollDetailsText(fullDetails: boolean, locale: string, creator: string, created_on: string, anonymous: boolean, is_closed: boolean): string {
    const text_anonymous = (anonymous) ? "Anonymous" : "Non-anonymous";
    const text_closed = (is_closed) ? "Closed" : "Open";

    const basic_text = text_closed + " | " + text_anonymous;

    if (fullDetails) {
        return "Created by " + creator + " on " + transformDateToDayMonthYear(locale, hyDate(created_on)) + " | " + basic_text;
    } else {
        return basic_text;
    }
}

function getSubmitDetailsText(have_limit: boolean, max_limit: number): string {
    if (!have_limit || max_limit == 1) { return ""; }
    return "You can submit max. " + max_limit + " answers per vote";
}


function getVoteNumberText(curValue: number, maxValue: number): string {
    const percent = _getVoteNumberPercent(curValue, maxValue);
    return percent + "% (" + curValue + ")";
}

function getVoteNumberImage(curValue: number, maxValue: number): string {
    const percent = _getVoteNumberPercent(curValue, maxValue);
    let imagePath = "";

    if (percent < 0) { imagePath = cdn_baseurl + "/cards/votes/percent_0.png"; }
    if (percent > 100) { imagePath = cdn_baseurl + "/cards/votes/percent_100.png"; }
    imagePath = cdn_baseurl + "/cards/votes/percent_" + percent + ".png";

    return imagePath;
}

function _getVoteNumberPercent(curValue: number, maxValue: number): number {
    if (maxValue <= 0) { return 0; }
    return Math.round((curValue / maxValue) * 100);
}

function getOpenClosePollProps(is_creater: boolean, is_closed: boolean): any {
    return {
        enabledOpenClosePoll: is_creater,
        iconOpenClosePoll: (is_closed) ? (cdn_baseurl + "/cards/iconLockOpen.png") : (cdn_baseurl + "/cards/iconLockClosed.png"),
        textOpenClosePoll: (is_closed) ? "Open poll" : "Close poll",
        idOpenClosePoll: (is_closed) ? actionGlobalOpenPoll : actionGlobalClosePoll,
    }
}

export function createCardFromPollData(refreshUserMRI: string | null, pollData: PollDataClass, paramShowInstallAppFooter: boolean, locale: string): any {

    const refreshTime = new Date();
    // Card expires after 30 seconds or 2 mins. Team will update card once 
    refreshTime.setUTCSeconds(refreshTime.getUTCSeconds() + (pollData.hot_reload ? 30 : (2 * 60)));

    const withFullDetails = (pollData.poll_view === EnumPolLView.result_details);

    if (pollData.poll_view === EnumPolLView.poll) {
        // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        // Show Poll
        // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

        // Filter, map, and join operations
        const defaultValue = pollData.answers
            .filter(item => item.pre_select)  // Filter to get items where pre_select is true
            .map(item => item.answer_guid)           // Map to retrieve the guid of each item
            .join(',');

        // Create Poll View
        const templatePoll = new ACData.Template(templateCardPoll);
        const cardPoll = templatePoll.expand({
            $root: {
                // Refresh data
                refreshUserMRIs: refreshUserMRI ? [refreshUserMRI] : [],
                refreshExpiration: refreshTime.toISOString(),

                // Submenu
                iconOpenApp: cdn_baseurl + "/cards/iconApp.png",
                iconRefresh: cdn_baseurl + "/cards/iconRefresh.png",
                iconEditPoll: cdn_baseurl + "/cards/iconEditPoll.png",
                ...getOpenClosePollProps(pollData.user_is_creator, pollData.poll_is_closed),
                iconRemovePoll: cdn_baseurl + "/cards/iconRemovePoll.png",

                // Header
                pollDetailsText: getPollDetailsText(withFullDetails, locale, pollData.created_by, pollData.created_on, pollData.option_votes_are_anonymous, pollData.poll_is_closed),

                // Question and Answers
                question: pollData.question,
                defaultValue: defaultValue,
                multiSelectAllowed: (!pollData.option_votes_have_limit || pollData.option_votes_max_limit > 1),
                answers: pollData.answers.map((answer) => ({
                    title: answer.text, // Using the 'text' property of each answer as the title
                    value: answer.answer_guid
                })),

                // Footer
                submitDetailsText: getSubmitDetailsText(pollData.option_votes_have_limit, pollData.option_votes_max_limit),

                // Action Data
                pollGuid: pollData.poll_guid,
                pollSecret: pollData.poll_secret,
            },
        });

        // Remove the refresh part from the json. This might cause a display bug inside
        // message preview area. If MRI is not supported, the adaptive card is most likely
        // be created for the first time. In this case, the refresh part is (necessarily) not needed.
        if (!refreshUserMRI) {
            if (cardPoll.refresh) {
                delete cardPoll.refresh;
            }
        }

        return cardPoll;

    } else if ((pollData.poll_view === EnumPolLView.result || pollData.poll_view === EnumPolLView.result_details) && !pollData.poll_results_restricted) {
        // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        // Show results (without restriction)
        // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        const templateResult = new ACData.Template(templateCardPollResult);
        const cardResult = templateResult.expand({
            $root: {
                // Refresh data
                refreshUserMRIs: refreshUserMRI ? [refreshUserMRI] : [],
                refreshExpiration: refreshTime.toISOString(),

                // Submenu
                iconOpenApp: cdn_baseurl + "/cards/iconApp.png",
                iconRefresh: cdn_baseurl + "/cards/iconRefresh.png",
                iconShowDetails: cdn_baseurl + "/cards/iconShowDetails.png",
                iconUpdateVote: cdn_baseurl + "/cards/iconUpdateVote.png",
                ...getOpenClosePollProps(pollData.user_is_creator, pollData.poll_is_closed),
                iconRemovePoll: cdn_baseurl + "/cards/iconRemovePoll.png",

                // Header
                resultHeaderSuffix: (pollData.poll_view === EnumPolLView.result_details) ? " Details" : "",
                pollDetailsText: getPollDetailsText(withFullDetails, locale, pollData.created_by, pollData.created_on, pollData.option_votes_are_anonymous, pollData.poll_is_closed),

                // Question and Answers
                question: pollData.question,
                answers: pollData.answers.map((answer) => ({
                    text: answer.text,
                    imageUrl: getVoteNumberImage(answer.answer_votes, pollData.poll_votes_total),
                    voteText: getVoteNumberText(answer.answer_votes, pollData.poll_votes_total),
                    voteNames: answer.voter_names
                })),

                // Footer
                showIsClosedText: (pollData.poll_is_closed),
                totalVotesText: getVoteNumberText(pollData.poll_votes_total, pollData.poll_votes_total),

                // IsInstallAppFooter
                showInstallAppFooter: false, //30.08.24, JKE: Deactivated due to direct bot message change (paramShowInstallAppFooter),

                // Action Data
                pollGuid: pollData.poll_guid,
                pollSecret: pollData.poll_secret,

            },
        });

        return cardResult;

    } else if (pollData.poll_view === EnumPolLView.result_thankyou) {
        const templateResult = new ACData.Template(templateCardPollResultThankYou);
        const cardResult = templateResult.expand({
            $root: {
                // Refresh data
                refreshUserMRIs: refreshUserMRI ? [refreshUserMRI] : [],
                refreshExpiration: refreshTime.toISOString(),

                // Submenu
                iconOpenApp: cdn_baseurl + "/cards/iconApp.png",
                iconRefresh: cdn_baseurl + "/cards/iconRefresh.png",
                iconShowDetails: cdn_baseurl + "/cards/iconShowDetails.png",
                iconUpdateVote: cdn_baseurl + "/cards/iconUpdateVote.png",
                ...getOpenClosePollProps(pollData.user_is_creator, pollData.poll_is_closed),
                iconRemovePoll: cdn_baseurl + "/cards/iconRemovePoll.png",

                // Question and Answers
                question: pollData.question,

                // Footer
                showIsClosedText: (pollData.poll_is_closed),

                // IsInstallAppFooter
                showInstallAppFooter: false, //30.08.24, JKE: Deactivated due to direct bot message change (paramShowInstallAppFooter),

                // Action Data
                pollGuid: pollData.poll_guid,
                pollSecret: pollData.poll_secret

            },
        });

        return cardResult;
    
    } else if ((pollData.poll_view === EnumPolLView.result || pollData.poll_view === EnumPolLView.result_details) && pollData.poll_results_restricted) {
        // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        // Show results (WITH restriction)
        // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

        const templateResult = new ACData.Template(templatePollResultRestricted);
        const cardResult = templateResult.expand({
            $root: {
                // Refresh data
                refreshUserMRIs: refreshUserMRI ? [refreshUserMRI] : [],
                refreshExpiration: refreshTime.toISOString(),

                // Submenu
                iconOpenApp: cdn_baseurl + "/cards/iconApp.png",
                iconRefresh: cdn_baseurl + "/cards/iconRefresh.png",
                iconShowDetails: cdn_baseurl + "/cards/iconShowDetails.png",
                iconUpdateVote: cdn_baseurl + "/cards/iconUpdateVote.png",
                ...getOpenClosePollProps(pollData.user_is_creator, pollData.poll_is_closed),
                iconRemovePoll: cdn_baseurl + "/cards/iconRemovePoll.png",

                // Header
                resultHeaderSuffix: (pollData.poll_view === EnumPolLView.result_details) ? " Details" : "",
                pollDetailsText: getPollDetailsText(withFullDetails, locale, pollData.created_by, pollData.created_on, pollData.option_votes_are_anonymous, pollData.poll_is_closed),

                // Question
                question: pollData.question,

                // iconPurchase: cdn_baseurl + "/cards/iconPurchase.png",
                iconEnterKey: cdn_baseurl + "/cards/iconEnterKey.png",

                // Footer
                showIsClosedText: (pollData.poll_is_closed),
                totalVotesText: getVoteNumberText(pollData.poll_votes_total, pollData.poll_votes_total),

                // Action Data
                pollGuid: pollData.poll_guid,
                pollSecret: pollData.poll_secret,
            },
        });

        return cardResult;

    } else if (pollData.poll_view === EnumPolLView.not_found) {

        const cardResult = createStandardCard("Poll not found", "The poll you are looking for does not exist.", "", "");
        return cardResult;

    } else {
        throw new Error("Poll view not known. Please contact support at support@easy-poll.app.");
    }

}

export function createStandardCard(title: string, text: string, user: string, error: string): any {

    // The user has chosen to create a card by choosing the 'Create Card' context menu command.
    const template = new ACData.Template(templateCardStandardText);
    const card = template.expand({
        $root: {
            title: title,
            text: text,
            user: user,
            error: error
        },
    });

    return card;
}

export function createDialogInstallBotCard(): any {

    const template = new ACData.Template(templateDialogInstallBot);
    return template.expand({ $root: {} });
}

export function createDialogStartConversationCard(): any {

    const template = new ACData.Template(templateDialogStartConversation);
    return template.expand({ $root: {} });
}


export function removeActionsFromCard_Old(card: any, removeSubmit: boolean): any {

    // Not used anymore, but kept for reference

    // Check if the current object contains "type": "Action.Execute"
    if (card?.type === 'Action.Execute' || card?.type === 'Action.OpenUrl' || card?.type === 'Action.Submit') {
        // Remove all actions
        if (removeSubmit) { return null; }

        // Remove all actions except the submit action
        if (!removeSubmit && card?.id !== "pollSubmitVote") { return null; }
    }

    // Iterate over the keys of the object
    for (const key in card) {
        if (card.hasOwnProperty(key)) {
            const value = card[key];

            // If the value is an object, recursively clean it
            if (typeof value === 'object' && value !== null) {

                const cleanedValue =
                    Array.isArray(value) ?
                        value.map(item => (typeof item === 'object' && item !== null ? removeActionsFromCard_Old(item, removeSubmit) : item)).filter(item => item !== null)
                        : removeActionsFromCard_Old(value, removeSubmit);

                // If cleanedValue is null, delete the key from the object
                if (cleanedValue === null) {
                    delete card[key];
                } else {
                    card[key] = cleanedValue;
                }
            }
        }
    }

    return card;
}

export interface AnyObject {
    [key: string]: any;
}

export function cleanCardFromActions(obj: AnyObject, cleanBottomAction: boolean): AnyObject {
    // Helper function to recursively process the object
    const recurse = (item: AnyObject, cleanBottomAction: boolean): AnyObject => {
        if (item && typeof item === 'object') {
            // Iterate over each property in the object
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    const value = item[key];

                    // If the value is an object and has a type of "ActionSet", delete it
                    if (value && typeof value === 'object' && value.type === 'ActionSet') {
                        delete item[key];
                    } else if (cleanBottomAction && key === 'actions') {
                        // If the key is "actions", delete the key and its content
                        delete item[key];
                    } else if (key === 'refresh') {
                        // If the key is "refresh", delete the key and its content
                        delete item[key];
                    } else if (value && typeof value === 'object') {
                        // Recursively process nested objects
                        item[key] = recurse(value, cleanBottomAction);
                    }
                }
            }
        }
        return item;
    };

    // Function to clean up null values from arrays
    const cleanUpArrays = (item: AnyObject): AnyObject => {
        if (item && typeof item === 'object') {
            if (Array.isArray(item)) {
                // Filter out null values from arrays
                return item.map(cleanUpArrays).filter(element => element !== null);
            } else {
                // Recursively clean arrays in nested objects
                for (const key in item) {
                    if (item.hasOwnProperty(key)) {
                        item[key] = cleanUpArrays(item[key]);
                    }
                }
            }
        }
        return item;
    };

    // Clone the original object to avoid mutating it
    const clonedObj = JSON.parse(JSON.stringify(obj));

    // Process the cloned object for ActionSet and refresh removal
    const cleanedObj = recurse(clonedObj, cleanBottomAction);

    // Perform a final cleanup to remove nulls from arrays
    return cleanUpArrays(cleanedObj);
}




export function checkEditVoteResponse(voteResult: CreateEditVoteResults, action_id: string): void {
    if (!voteResult.success) {

        if (voteResult.code === CreateEditVoteWarnings.limit_violation) {
            throw new Warning("Vote not submitted: Please don't select more than " + voteResult.pollData.option_votes_max_limit + " answers.");
        }
        else if (voteResult.code === CreateEditVoteWarnings.no_answer_selected) {
            throw new Warning("Vote not submitted: Please select at least 1 answer.");
        }
        else if (voteResult.code === CreateEditVoteWarnings.poll_is_closed) {
            if (action_id == actionResultsCardEditVote) {
                throw new Warning("The poll is already closed. Your vote can not be edited.");
            } else if (action_id === actionPollSubmitVote) {
                // Do nothing, card with results will be shown.
            }

        } else if (voteResult.code === CreateEditVoteWarnings.poll_not_found) {
            // Do nothing, card with error message will be shown.
        } else {
            throw new Error((voteResult.warning) ? "Error: " + voteResult.warning : "Unknown error (210).");
        }
    }
}


export function checkEditPollResponse(editPolLResult: CreatePollResults): void {

    if (!editPolLResult.success) {
        if (editPolLResult.code == EditPollWarnings.user_is_not_creator) {
            throw new Warning("This action can only be performed by the poll creator.");
        } else if (editPolLResult.code == EditPollWarnings.unlock_poll_already_used) {
            throw new Warning("The creator has already unlocked a poll this month.");
        } else {
            throw new Error((editPolLResult.warning) ? "Error: " + editPolLResult.warning : "Unknown error (211).");
        }

    }


}