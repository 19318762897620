import { Image, Title3, Text, Link, Button } from "@fluentui/react-components";
import { EmptyFlexRow, Flex } from "./blocks/FlexBoxes";
import { MainContainer } from "./components/MainContainer";

import { ImprintTab } from "./components/CommonMisc";
import { useContext, useEffect } from "react";
import { AppContext } from "sub/context/AppContext";
import { NoAccessReason } from "./library/useAppAuthorization";
import { link_teams_app } from "./library/epCommon";
import { backendTrackEvent } from "./library/epBackend";

export default function NoAccessPage() {

    const appContext = useContext(AppContext);

    let title = "";
    let subtitle = "";
    let message = (<></>);

    useEffect(() => {
        // Provide error to backend for statistics
        const event_details = {
            reason : appContext.appAuthData.noAccessReason,
            details : appContext.appAuthData.noAccessDetails
        }
        backendTrackEvent(appContext.appAuthData.lang, "Tab", "NoAccessPage", JSON.stringify(event_details) , appContext);
    }, []);

    switch (appContext.appAuthData.noAccessReason) {

        case NoAccessReason.not_in_teams:
            title = "";
            subtitle = "Enhance your team's decision-making process with Easy Poll. Easy Poll allows you to seamlessly create and manage polls directly within Microsoft Teams. This makes it an essential asset for gathering opinions and making informed decisions in your team or organization.";
            message =
                (<>
                    <Image src="easypoll_example_poll.png" fit="default" style={{ maxWidth: "80%", maxHeight: "400px" }} />
                    <EmptyFlexRow size="1.0" />
                    <Text weight="bold">Please run Easy Poll inside Microsoft Teams.</Text>
                    <EmptyFlexRow size="1.0" />
                    <Button appearance="primary" onClick={() => { window.open(link_teams_app, "_blank") }}>Open Teams app</Button>
                </>);
            break;

        case NoAccessReason.error_currently_down:
            title = "Easy Poll down for maintenance";
            message =
                (<>
                    <Image src="hello.png" style={{ maxWidth: "80%", maxHeight: "200px" }} />
                    <EmptyFlexRow size="1.0" />
                    <Text wrap>Uh-oh. We've encountered an issue that is preventing you from creating and voting on a poll. We are working with top priority to get Easy Poll back up and running. In the meantime, we apologize for any inconvenience. Please try again later or contact support at <Link inline href="mailto:support@easy-poll.app">support@easy-poll.app</Link>.</Text>
                    <EmptyFlexRow size="1.0" />
                    <Button appearance="primary" onClick={() => { window.location.reload() }}>Reload</Button>
                </>);
            break;

        case NoAccessReason.error_no_user:
            title = "Guest account not supported";
            message =
                (<>
                    <Image src="hello.png" style={{ maxWidth: "80%", maxHeight: "200px" }} />
                    <EmptyFlexRow size="1.0" />
                    <Text wrap>Users with a guest account are not able to use Easy Poll. Please login to Microsoft Teams with your work account. If you think this is an error, please try again or contact support at <Link inline href="mailto:support@easy-poll.app">support@easy-poll.app</Link>.</Text>
                </>);
            break;


        default:
            title = "Error";
            message =
                (<>
                    <Image src="hello.png" style={{ maxWidth: "80%", maxHeight: "200px" }} />
                    <EmptyFlexRow size="1.0" />
                    <Text wrap>Oh Oh. Something went wrong. Please try again later or contact support at <Link inline href="mailto:support@easy-poll.app">support@easy-poll.app</Link>.</Text>
                    <Text wrap>Code: {appContext.appAuthData.noAccessReason.toString()}</Text>
                    <EmptyFlexRow size="1.0" />
                    <Button appearance="primary" onClick={() => { window.location.reload() }}>Reload</Button>
                </>);
            break;

    }

    return (

        <MainContainer botDialog>
            <Flex column fillFlex gap="smaller" padding="medium" alignItems="center">
                <Flex fillFlex column gap="smaller" alignItems="center" justifyContent="center" style={{ width: "80%", maxWidth: "850px" }}>
                    <Image src="easypoll_header.svg" height={50} />
                    <EmptyFlexRow size="1.0" />
                    <Title3>{title}</Title3>
                    {subtitle && <><EmptyFlexRow size="0.5" /><Text>{subtitle}</Text></>}
                    <EmptyFlexRow size="1.0" />

                    {message}
                </Flex>

                <ImprintTab />
            </Flex>
        </MainContainer>

    );
}


