import { Button, Spinner, Title3 } from "@fluentui/react-components";
import { Flex, FlexItem } from "./blocks/FlexBoxes";
import { PollContextClass, PollContext, EnumPollWorkflow } from "./context/PollContext";
import { SaveSearchRegular, PollHorizontalRegular } from "@fluentui/react-icons";
import { useContext, useEffect, useRef, useState } from "react";
import { PollCreateOrEdit } from "./components/PollCreateOrEdit";
import { AppContext, EnumAppEnvironment } from "sub/context/AppContext";
import { PollPreview } from "./components/PollPreview";
import { MainContainer } from "./components/MainContainer";
import { EnumPolLView, PollDataClass } from "./bot-client-shared/PollDataClass";
import { PollReuseTable } from "./components/PollReuseTable";
import { TabContext, TabContextClass, TabDialogType, TabTableMode } from "./context/TabContext";
import { ListPollResults } from "./library/epBackendTypes";
import { getPollDataToNewPollEntry } from "./library/epCommon";
import { backendGetPollOrResultClient, backendTrackEvent } from "./library/epBackend";
import { ImprintChat, ProTag, TextFreePlan } from "./components/CommonMisc";


export default function EntryChat() {

    // App context:
    const appContext = useContext(AppContext);
    appContext.environment.current = EnumAppEnvironment.message_chat;

    // Poll context:
    // Used mainly for creating new polls and dialog behaviour.
    const [pollWorkflow, setPollWorkflow] = useState(EnumPollWorkflow.task_selector);

    const [contextPollData, setContextPollData] = useState(new PollDataClass());
    const updatePollData = (updates: Partial<PollDataClass>) => {
        setContextPollData(current => ({ ...current, ...updates }));
    };

    const pollContext: PollContextClass =
    {
        pollWorkflow: pollWorkflow,
        setPollWorkflow: setPollWorkflow,

        pollData: contextPollData,
        updatePollData: updatePollData,
    };

    // Tab Context:
    // Used mainly for reusing polls.
    const [tabDialog, setTabDialog] = useState(TabDialogType.None);

    const listPollResults = useRef<ListPollResults>(new ListPollResults());

    const [tableMode, setTableMode] = useState(TabTableMode.modePolls);
    const tableSearch = useRef<string>("");
    const activePollElement = useRef<PollDataClass>(new PollDataClass());

    const [refreshTickerData, setRefreshTickerData] = useState(1);
    const [refreshTickerGUI, setRefreshTickerGUI] = useState(1);

    const tabContext: TabContextClass = {
        tabDialog: tabDialog,
        setTabDialog: setTabDialog,

        listPollResults: listPollResults,

        tableMode: tableMode,
        setTableMode: setTableMode,
        tableSearch: tableSearch,
        activePollElement: activePollElement,

        refreshTickerData: refreshTickerData,
        setRefreshTickerData: setRefreshTickerData,
        refreshTickerGUI: refreshTickerGUI,
        setRefreshTickerGUI: setRefreshTickerGUI,
    };

    // TabDialog handling
    // Special logic for chat: DuplicateTemplate is not supported in chat environment.
    useEffect(() => {
        if (tabContext.tabDialog === TabDialogType.ViewOrPostPoll) {
            setPollWorkflow(EnumPollWorkflow.reusePoll_loading);
            tabContext.setTabDialog(TabDialogType.None); // Reset tab Dialog

        } else if (tabContext.tabDialog === TabDialogType.DuplicatePoll) {
            setPollWorkflow(EnumPollWorkflow.duplicatePoll_loading);
            tabContext.setTabDialog(TabDialogType.None); // Reset tab Dialog
        }

    }, [tabContext.tabDialog]);

    // Poll Workflow handling
    // Special logic for chat: DuplicateTemplate is not supported in chat environment.
    useEffect(() => {
        if (pollWorkflow === EnumPollWorkflow.duplicatePoll_loading) {

            const fetchData = async () => {
                await getPollDataToNewPollEntry(false, tabContext, appContext, setPollWorkflow, setContextPollData);
            }

            fetchData();
        } else if (pollWorkflow === EnumPollWorkflow.reusePoll_loading) {
            const fetchData = async () => {
                // Get detailed poll data from backend 
                const getPollResult = await backendGetPollOrResultClient(EnumPolLView.poll, appContext.appAuthData.userUPN, appContext.appAuthData.userID, appContext.appAuthData.userToken,
                    appContext.appAuthData.displayName, appContext.appAuthData.lang,
                    tabContext.activePollElement.current.poll_guid, tabContext.activePollElement.current.poll_secret);

                // Update the active Poll from PollContext with detailed data
                pollContext.updatePollData(getPollResult.pollData);

                // Set the workflow to reusePoll_preview
                pollContext.setPollWorkflow(EnumPollWorkflow.reusePoll_preview);
            };
            fetchData();
        }

    }, [pollWorkflow]);

    return (
        <PollContext.Provider value={pollContext}>
            <TabContext.Provider value={tabContext}>
                <MainContainer botDialog>
                    <Flex column fillFlex gap="smaller" padding="medium" style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                        {(pollWorkflow === EnumPollWorkflow.task_selector) && <CreatePollChooser hasActiveLicense={appContext.appAuthData.hasActiveLicense} setWorkflow={setPollWorkflow} setPollData={setContextPollData} />}

                        {(pollWorkflow === EnumPollWorkflow.duplicatePoll_loading || pollWorkflow === EnumPollWorkflow.reusePoll_loading) &&
                            (
                                <Flex column fillFlex justifyContent="center">
                                    <Spinner />
                                </Flex>
                            )}

                        {(pollWorkflow === EnumPollWorkflow.newPoll_entry) && <PollCreateOrEdit />}
                        {(pollWorkflow === EnumPollWorkflow.newPoll_preview) && <PollPreview />}

                        {(pollWorkflow === EnumPollWorkflow.reusePoll_table) && <PollReuseTable />}
                        {(pollWorkflow === EnumPollWorkflow.reusePoll_preview) && <PollPreview />}

                    </Flex>
                </MainContainer>
            </TabContext.Provider>

        </PollContext.Provider>
    );
}

function CreatePollChooser(props: { hasActiveLicense: boolean, setWorkflow: React.Dispatch<React.SetStateAction<EnumPollWorkflow>>, setPollData: React.Dispatch<React.SetStateAction<PollDataClass>> }) {
    /*
        <Image src="easypoll_header.svg" height={40} />
        <EmptyFlexRow size="1.0" />
    */

    return (
        <Flex column fillFlex>
            <Flex gap="small" alignItems="center">
                <Title3>&nbsp;</Title3>
                <FlexItem push>
                    {!props.hasActiveLicense && (<Flex justifyContent="center" gap="smaller" style={{ color: "red" }}><TextFreePlan /></Flex>)}
                    {props.hasActiveLicense && (<Flex justifyContent="center" gap="smaller" style={{ color: "red" }}><ProTag /></Flex>)}
                </FlexItem>
            </Flex>

            <Flex column fillFlex justifyContent="center" alignItems="center" gap="large">
                <Button icon={<PollHorizontalRegular />} appearance="primary" style={{ width: "15em" }} onClick={() => { _newPoll(); }}>Blank poll</Button>
                <Button icon={<SaveSearchRegular />} style={{ width: "15em" }} onClick={() => { _selectTask(); }}>Existing poll / template</Button>
            </Flex>
            <ImprintChat />
        </Flex>
    );

    function _newPoll() {
        props.setPollData(new PollDataClass());
        props.setWorkflow(EnumPollWorkflow.newPoll_entry);
    }

    function _selectTask() {
        props.setPollData(new PollDataClass());
        props.setWorkflow(EnumPollWorkflow.reusePoll_table);
    }

}

