import { useContext, useEffect, useRef, useState } from "react";

import { Button, Link, Caption1, tokens } from "@fluentui/react-components";
import { BookmarkRegular, PollHorizontalRegular, PremiumRegular, PersonKeyRegular } from "@fluentui/react-icons";

import { MainContainer } from "./components/MainContainer";
import { ImprintTab, ProTag } from "./components/CommonMisc";

import { EmptyFlexRow, Flex, FlexItem } from "./blocks/FlexBoxes";

import { AppContext, EnumAppEnvironment } from "./context/AppContext";
import { TabContext, TabContextClass, TabDialogType, TabTableMode } from "./context/TabContext";

import { ListPollResults } from "./library/epBackendTypes";
import { PollDataClass } from "./bot-client-shared/PollDataClass";
import { TabDialogGetStarted } from "./components/TabDialogGetStarted";
import { ListPollsTable } from "./components/ListPollsTable";
import { TabDialogDeletePoll } from "./components/TabDialogDeletePoll";
import { ListPollsSearch } from "./components/ListPollsSearch";
import { TabDialogViewPoll } from "./components/TabDialogViewPoll";
import { TabDialogCreateDraft } from "./components/TabDialogCreateDraft";
import { TabDialogEditVote } from "./components/TabDialogEditVote";
import { isMobile } from "react-device-detect";
import { TabDialogManageLicense } from "./components/TabDialogManageLicense";
import { backendTrackEvent } from "./library/epBackend";
import { ListPollsModeSelector } from "./components/ListPollsMode";
import { link_teams_app, refreshTicker } from "./library/epCommon";
import { hyDebug } from "./library/hyDebug";
import { pages } from "@microsoft/teams-js";


export default function EntryTab() {

    // App context:
    const appContext = useContext(AppContext);
    appContext.environment.current = EnumAppEnvironment.personal_tab;

    // Create variables for tab context
    const [tabDialog, setTabDialog] = useState(TabDialogType.None);

    const listPollResults = useRef<ListPollResults>(new ListPollResults());

    const [tableMode, setTableMode] = useState(TabTableMode.modePolls);
    const tableSearch = useRef<string>("");

    const activeTablePollElement = useRef<PollDataClass>(new PollDataClass());

    const [refreshTickerData, setRefreshTickerData] = useState(1);
    const [refreshTickerGUI, setRefreshTickerGUI] = useState(1);

    const tabContext: TabContextClass = {
        tabDialog: tabDialog,
        setTabDialog: setTabDialog,

        listPollResults: listPollResults,

        tableMode: tableMode,
        setTableMode: setTableMode,
        tableSearch: tableSearch,
        activePollElement: activeTablePollElement,

        refreshTickerData: refreshTickerData,
        setRefreshTickerData: setRefreshTickerData,
        refreshTickerGUI: refreshTickerGUI,
        setRefreshTickerGUI: setRefreshTickerGUI,
    };

    // Define intial dialogs.
    useEffect(() => {

        backendTrackEvent(appContext.appAuthData.lang, "Tab", "ListPolls", "", appContext);

        if (!appContext.viewSettings.storage.hideGetStartedOnStartup) {
            // Show get started dialog if not hidden by default
            setTabDialog(TabDialogType.GetStarted);
            return;
        }

        // Check deep links
        if (appContext.appDeepLink.current) {
            const appDeepLink = appContext.appDeepLink.current;
            // Reset deep link to avoid re-triggering
            appContext.appDeepLink.current = "";
            hyDebug("Take action for deep link: " + appDeepLink)

            switch (appDeepLink) {
                case "upgrade":
                    setTabDialog(TabDialogType.ManageLicense);
                    break;

                // Deep link for templates was deactived. 
                // This is now handled by setting the proper table mode in storage before loading the app.
                case "template":
                    setTabDialog(TabDialogType.CreateTemplate);
                    break;
            }
        }
        return;

    }, []);

    return (

        <TabContext.Provider value={tabContext}>
            <MainContainer>
                <Flex column fillFlex gap="small" alignItems="center" padding="medium">
                    <Flex column fillFlex gap="smaller" style={{ width: (isMobile ? "90%" : "80%"), maxWidth: "1000px", overflow: 'auto' }}>

                        {(tabDialog === TabDialogType.GetStarted) && (<TabDialogGetStarted />)}

                        {(tabDialog === TabDialogType.CreateDraft || tabDialog === TabDialogType.CreateTemplate || tabDialog === TabDialogType.DuplicatePoll || tabDialog === TabDialogType.DuplicateTemplate || tabDialog === TabDialogType.EditPoll) && (<TabDialogCreateDraft />)}
                        {(tabDialog === TabDialogType.DeletePoll || tabDialog === TabDialogType.DeleteTemplate) && (<TabDialogDeletePoll />)}
                        {(tabDialog === TabDialogType.ViewOrPostPoll || tabDialog === TabDialogType.ViewTemplate) && (<TabDialogViewPoll />)}

                        {(tabDialog === TabDialogType.EditVote) && (<TabDialogEditVote />)}
                        {(tabDialog === TabDialogType.ManageLicense) && (<TabDialogManageLicense />)}
                        <EmptyFlexRow size="1.0" />

                        <Flex alignItems="center" gap="medium" wrap>
                            {tabContext.tableMode === TabTableMode.modePolls && <ListPollsModeSelector />}
                            {tabContext.tableMode === TabTableMode.modeTemplates && <ListPollsModeSelector />}

                            {appContext.appAuthData.hasActiveLicense && <ProTag />}
                            <FlexItem push>
                                <Link onClick={() => setTabDialog(TabDialogType.GetStarted)}>Get started</Link>
                            </FlexItem>
                            <ListPollsSearch />
                            <CreateButton />

                            {/* appearance: transparent and style: style={{ paddingLeft: "0px", minWidth: "fit-content" }} */}
                            {!appContext.appAuthData.hasActiveLicense && (<Button icon={<PremiumRegular />} appearance="secondary" onClick={() => setTabDialog(TabDialogType.ManageLicense)} title="Upgrade to Pro plan">Upgrade</Button>)}
                            {appContext.appAuthData.hasActiveLicense && (<Button icon={<PersonKeyRegular />} appearance="secondary" onClick={() => setTabDialog(TabDialogType.ManageLicense)} title="Manage your license">Manage</Button>)}

                        </Flex>
                        <Caption1 style={{ paddingLeft: tokens.spacingHorizontalSNudge }}>{getSubHeadlineText(tabContext.tableMode)}</Caption1>

                        <EmptyFlexRow size="1.5" />

                        <ListPollsTable />

                    </Flex>
                    <EmptyFlexRow size="0.25" />
                    <ImprintTab />
                </Flex>
            </MainContainer >
        </TabContext.Provider>

    );

    function CreateButton(props: {}) {
        let title = "";
        let icon: JSX.Element;
        let targetType: TabDialogType;
        if (tabContext.tableMode === TabTableMode.modePolls) {
            title = "Draft a new poll";
            icon = <PollHorizontalRegular />;
            targetType = TabDialogType.CreateDraft;
        } else {
            title = "Create template";
            icon = <BookmarkRegular />;
            targetType = TabDialogType.CreateTemplate;
        }

        return (<Button icon={icon} appearance="primary" onClick={() => setTabDialog(targetType)}>{title}</Button>);
    }
}



function getSubHeadlineText(mode: TabTableMode) {
    if (mode === TabTableMode.modePolls) {
        return "Presents the polls you've either created or cast a vote on.";
    } else {
        return "Presents the templates you've created.";
    }
}
